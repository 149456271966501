.workMain {
  margin: 60px 0px 0px;
  position: absolute;
  width: 100%;
  background-color: #ffedd5;
  padding: 8px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  left: 0;
}
.workStatus{
    background-color: #dd6b20;
    color: white;
    padding: 2px 8px;
    border-radius: 3px;
    animation: colorChange 2s infinite;
}
@keyframes colorChange {
  0% {background-color: #dd6b20;}
  25%{background-color:#ff934b;}
  50%{background-color:#dd6b20;}
  75%{background-color:#ff934b;}
  100% {background-color: #dd6b20;}
}