@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
.circle-sketch-highlight a {
  text-decoration: none !important;
  color: black;
}
.headerMain {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 120px 0px 50px;
  position: relative;
}
.subtleHeaderBackOne,
.subtleHeaderBackTwo {
  position: absolute;
  font-size: 10rem;
  font-weight: 600;
  color: rgba(0, 0, 255, 0.055);
  z-index: -1;
}
.subtleHeaderBackOne {
  top: -100px;
  right: 50px;
}
.subtleHeaderBackTwo {
  top: 200px;
  left: 80px;
}
.headerContainer {
  max-width: 1100px;
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 50px;
}
.headerContainer .textSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 650px;
}
.headerContainer .textSection .textHeading {
  font-size: 2.5rem;
  font-weight: 800;
}
.headerContainer .textSection .textDesc {
  font-size: 1.1rem;
  padding: 20px 0px;
  line-height: 1.5rem;
}
.textSectionButtonsDiv {
  display: flex;
}
.textSectionButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
.textSectionButtonsDiv .textSectionButton {
  font-family: "Karla", sans-serif;
  padding: 0.75rem 2rem;
  font-size: 1.1rem;
  font-weight: 500;
  background-color: white;
  border-radius: 5px;
  outline: none !important;
  border: 1px solid rgb(214, 214, 214);
  transition: 0.2s ease;
}
.textSectionButtonsDiv .textSectionButton:hover {
  transform: scale(1.05);
  transition: 0.2s ease;
  cursor: pointer;
}
.textSectionLinkedin {
  background-color: rgb(14, 24, 33) !important;
  color: white;
  margin-right: 20px;
  outline: none !important;
  border: 1px solid rgb(14, 24, 33) !important;
}
.headerContainer .imageSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.headerContainer .imageSection img {
  width: 400px;
  /* height: 500px; */
  /* border-radius: 800px; */
  /* box-shadow:
      0 1px 1px hsl(0deg 0% 0% / 0.075),
      0 2px 2px hsl(0deg 0% 0% / 0.075),
      0 4px 4px hsl(0deg 0% 0% / 0.075),
      0 8px 8px hsl(0deg 0% 0% / 0.075),
      0 16px 16px hsl(0deg 0% 0% / 0.075)
    ; */
  /* width: 250px;
	height: 250px;
	background-color: rgba(21, 24, 35, 0.7);
	box-shadow: inset 2px 2px 10px rgba(0, 0, 0, 0.3); */
}

/* -----------Highlight----------- */

.highlight-container,
.highlight {
  position: relative;
}

.highlight-container {
  display: inline-block;
}
.highlight-container:before {
  content: " ";
  display: block;
  height: 90%;
  width: 100%;
  margin-left: -3px;
  margin-right: -3px;
  position: absolute;
  background: var(--main-bg-color);
  transform: rotate(2deg);
  top: -1px;
  left: 2px;
  border-radius: 20% 25% 20% 24%;
  padding: 5px 1px;
  z-index: -1;
}
.span1 {
  --main-bg-color: rgba(250, 201, 228);
}
.span2 {
  --main-bg-color: rgba(250, 197, 195);
}
.span3 {
  --main-bg-color: rgba(255, 230, 116);
}
.span4 {
  --main-bg-color: rgba(228, 206, 252);
}
.span5 {
  --main-bg-color: rgba(248, 201, 228);
}
.span6 {
  --main-bg-color: rgba(251, 194, 193);
}
.span7 {
  --main-bg-color: rgba(250, 228, 114);
}
.span8 {
  --main-bg-color: rgba(231, 207, 255);
}

/* ---------Cicular Strike--------- */

.circle-sketch-highlight {
  position: relative;
}

.circle-sketch-highlight:before {
  content: "";
  z-index: -1;
  left: -0.5em;
  top: -0.1em;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(241, 79, 76);
  position: absolute;
  border-right-color: transparent;
  width: 100%;
  height: 1em;
  transform: rotate(2deg);
  opacity: 0.7;
  border-radius: 50%;
  padding: 0.1em 0.25em;
}

.circle-sketch-highlight:after {
  content: "";
  z-index: -1;
  left: -0.5em;
  top: 0.1em;
  padding: 0.1em 0.25em;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(241, 79, 76);
  border-left-color: transparent;
  border-top-color: transparent;
  position: absolute;
  width: 100%;
  height: 1em;
  transform: rotate(-1deg);
  opacity: 0.7;
  border-radius: 50%;
}
.circle-sketch-highlight:hover {
  cursor: pointer;
}

/* ------------Responsiveness------------ */

@media (max-width: 1203px) {
  .headerContainer {
    max-width: 950px;
  }
}
@media (max-width: 1050px) {
  .headerContainer {
    max-width: 1000px;
    grid-template-columns: auto;
  }
  .headerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .headerContainer .textSection {
    order: 2;
    text-align: center;
    max-width: unset;
    padding: 0px 50px;
  }
  .headerContainer .imageSection {
    order: 1;
  }
  .textSectionButtonsDiv {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 1020px) {
  .headerContainer {
    max-width: 900px;
    grid-template-columns: auto;
  }
}
@media (max-width: 999px) {
  .subtleHeaderBackOne,
  .subtleHeaderBackTwo {
    font-size: 8rem;
  }
}
@media (max-width: 928px) {
  .headerContainer {
    max-width: 800px;
  }
}
@media (max-width: 824px) {
  .headerContainer {
    max-width: 700px;
  }
  .headerContainer .textSection {
    padding: 0px 20px;
  }
  .subtleHeaderBackOne,
  .subtleHeaderBackTwo {
    font-size: 6rem;
  }
}
@media (max-width: 700px) {
  .subtleHeaderBackOne,
  .subtleHeaderBackTwo {
    font-size: 6rem;
  }
  .subtleHeaderBackTwo {
    top: 200px;
    left: 20px;
  }
}
@media (max-width: 585px) {
  .subtleHeaderBackOne,
  .subtleHeaderBackTwo {
    font-size: 5rem;
  }
  .subtleHeaderBackTwo {
    top: 200px;
    left: 20px;
  }
}
@media (max-width: 532px) {
  .headerContainer {
    max-width: 400px;
    grid-gap: 10px;
  }
  .subtleHeaderBackOne,
  .subtleHeaderBackTwo {
    position: absolute;
    font-size: 2.5rem;
    font-weight: 800;
    color: rgba(0, 0, 255, 0.11);
    z-index: -1;
  }
  .subtleHeaderBackOne {
    top: -30px;
    right: 50px;
  }
  .subtleHeaderBackTwo {
    top: 50px;
    left: 10px;
  }
  .headerContainer .textSection {
    padding: 0px;
  }
  .headerContainer .imageSection {
    /* overflow-y: hidden; */
    /* border-radius: 100%; */
    /* border: 5px solid rgb(131, 147, 238); */
    /* padding: 0px 2px; */
    /* border: 5px solid; */
    /* background: #cadff7;  */
  }

  .headerContainer .imageSection img {
    width: 220px;
    height: 220px;
    border-radius: 10%;
  }
  .headerMain {
    margin: 80px 0px 50px !important;
  }
  .textSectionButtonsDiv .textSectionButton {
    font-size: 1rem;
    padding: 0.75rem 1.2rem;
  }
}
@media (max-width: 420px) {
  .headerContainer {
    max-width: 395px;
  }
  .headerContainer .textSection {
    padding: 0px;
  }
  .headerMain {
    margin: 10px 0px 50px;
  }
  .subtleHeaderBackOne {
    top: 50px;
    right: 50px;
  }
  .subtleHeaderBackTwo {
    top: 120px;
    left: 20px;
    font-size: 2.3rem;
  }
}
@media (max-width: 398px) {
  .headerContainer {
    max-width: 380px;
  }
  .headerContainer .textSection {
    padding: 0px;
  }
  .headerMain {
    margin: 10px 0px 50px;
  }
  .hireModal {
  width: 330px !important;
  padding: 15px 5px;
}
}
.hireModal {
  width: 350px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 10px;
}
.hireButtonList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}
.hireButtonList button {
  font-family: "Karla", sans-serif !important;
  min-width: 150px;
  height: 50px;
  background: #968ed3 !important;
  color: white;
  font-size: 1.1rem;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s ease;
  align-items: center !important;
  justify-content: center !important;
  display: flex !important;
  margin: 10px 0px;
  transition: all 0.3s ease;
}
.hireButtonList button:hover {
  transform: scale(1.05);
  transition: all 0.3s ease;
  background: #7670a7 !important;
  webkit-box-shadow: 6px 9px 14px 0px rgba(215, 213, 222, 0.88);
  -moz-box-shadow: 6px 9px 14px 0px rgba(215, 213, 222, 0.88);
  box-shadow: 6px 9px 14px 0px rgba(215, 213, 222, 0.88);
}
