.contributeMain {
  background-color: #141414;
  color: #81e6d9;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 130px 0px;
}
.contributeContainer {
  display: flex;
  flex-direction: column;
  max-width: 1100px;
  width: 100%;
  align-items: center;
}
.contributeHeading {
  font-weight: 600;
  font-size: 4rem;
}
.contributeSubHeading {
  font-weight: 500;
  font-size: 2.8rem;
  color: #319795 !important;
}
.contributeDesc {
  margin: 50px 0px;
  font-size: 1.8rem;
  max-width: 800px;
}
.contributeButtonDiv {
  width: 380px;
  display: flex;
  justify-content: space-between;
}
.contributeButtonDiv button {
  width: 180px;
  height: 50px;
  background-color: #319795;
  border: none;
  color: white;
  font-size: 1.4rem;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;
}
.contributeButtonDiv button:hover{
  transform: scale(1.05);
  cursor: pointer;
  transition: all 0.2s ease;
}
@media (max-width: 830px) {
  .contributeHeading {
    font-size: 3.4rem;
  }
  .contributeSubHeading {
    font-size: 2.9rem;
  }
  .contributeDesc {
    font-size: 1.5rem;
    max-width: 600px;
  }
}
@media (max-width: 660px) {
  .contributeHeading {
    font-size: 3.0rem;
  }
  .contributeSubHeading {
    font-size: 2.4rem;
  }
  .contributeDesc {
    font-size: 1.3rem;
    max-width: 500px;
  }
}
@media (max-width: 570px) {
  .contributeHeading {
    font-size: 2.5rem;
  }
  .contributeSubHeading {
    font-size: 2.0rem;
  }
  .contributeDesc {
    font-size: 1.3rem;
    max-width: 450px;
  }
}
@media (max-width: 480px) {
  .contributeHeading {
    font-size: 2.2rem;
  }
  .contributeSubHeading {
    font-size: 1.8rem;
  }
  .contributeDesc {
    font-size: 1.3rem;
    max-width: 400px;
  }
}
@media (max-width: 425px) {
  .contributeMain{
    padding: 100px 0px;
  }
  .contributeHeading {
    font-size: 2.0rem;
  }
  .contributeSubHeading {
    font-size: 1.8rem;
  }
  .contributeDesc {
    font-size: 1.2rem;
    max-width: 360px;
  }
  .contributeButtonDiv{
    width: 320px;
    flex-direction: column;
  }
  .contributeButtonDiv button{
    width: 100%;
    margin: 10px 0px;
    font-size: 1.3rem;
  }
}
