.modalContainer {
  padding: 50px 0px;
  width: 500px;
  /*
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;


  position: "absolute";
  top: "50%";
  left: "50%";
  transform: "translate(-50%, -50%)";
  width: 400;
  background-color: white;
  border: "2px solid #000";
  box-shadow: 24;
  padding: 4;
  z-index: 50000; */
}
.modalContainer .projectHeaderText {
  font-size: 2rem !important;
  font-weight: 800;
  color: #3a22cc;
  margin-bottom: 10px;
}
.modalContainer .statusCode {
  padding: 10px 0px !important;
  background-color: #ffedd5;
}
.modalContainer .colorChange {
  background-color: #dd6b20;
  color: white;
  padding: 2px 5px;
  border-radius: 5px;
}
.modalContainer .projectDescText {
  font-size: 1.1rem !important;
  font-weight: 500;
  padding: 10px 50px;
  margin-bottom: 10px;
}
.modalContent {
  font-family: "Karla", sans-serif !important;
  background-color: #fcfdff !important;
  width: 500px;
  min-height: 350px;
  border-radius: 10px;
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  /* align-items: center; */
}
.modalButtonList {
  min-width: 350px !important;
  display: flex;
  /* flex-direction: row; */
  justify-content: space-evenly;
  align-items: center;
}
.modalButtonList button {
  font-family: "Karla", sans-serif !important;
  width: 180px;
  height: 50px;
  background: #968ed3 !important;
  color: white;
  font-size: 1.1rem;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s ease;
  align-items: center !important;
  justify-content: center !important;
  display: flex !important;
}
.modalButtonList button:hover {
  background-color: #4d3faa;
  transform: scale(1.05);
  transition: 0.3s ease;
}
.modalButtonList button img {
  color: white !important;
}

@media (max-width: 525px) {
  .modalContainer {
    width: 450px ;
  }
}
@media (max-width: 465px) {
  .modalContainer {
    width: auto;
    padding: 30px;
  }
  .modalContainer .projectDescText {
    font-size: 1.1rem !important;
    font-weight: 500;
    padding: 10px;
    margin-bottom: 10px;
  }
  .modalContainer button {
    width: 150px;
  }
}
@media (max-width: 440px) {
  .modalContainer {
    width: auto;
    /* margin: 0px 10px; */
  }
  .modalContainer button {
    width: 150px;
  }
}
@media (max-width: 415px) {
  .modalContainer {
    width: auto;
    padding: 15px 10px;
  }
  .modalContainer button {
    width: 150px;
  }
}
@media (max-width: 390px) {
  .modalContainer {
    width: auto;
    padding: 15px 5px;
  }
  .modalContainer button {
    width: 150px;
  }
}
