.appMain {
  /* background-color:  red !important; */
  /* position: absolute; */
  overflow-y: hidden;
  
}
.parallaxContainer {
  transition: all ease;
}
a {
  text-decoration: none !important;
}
.appSpanDiv {
  background-image: linear-gradient(
    to bottom,
    #fcfdff,
    #fafcff,
    #f9fbff,
    #f7f9ff,
    #f6f8ff,
    #f6f7ff,
    #f5f7ff,
    #f5f6ff,
    #f5f6ff,
    #f5f6ff,
    #f5f6ff,
    #f5f6ff
  );
}
