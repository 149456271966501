.navBarMain {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px 0px;
  font-weight: 600;
  font-size: 1.1rem;
  color: rgba(71, 71, 71, 0.637);

  position: fixed;
  top: 0;
  background-color: rgba(232, 235, 250, 0.116);
  backdrop-filter: blur(5px);
  z-index: 10;
}
.navBarContainer .brandName {
  font-weight: 800;
  display: flex;
  align-items: center;
  color: #3a21cb !important;
  cursor: pointer;
}
#brandLogo {
  width: 35px;
  border-radius: 5px;
  /* display: none; */
  z-index: 10;
  margin-right: 5px;
}
.navBarContainer {
  max-width: 1100px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  align-items: center;
}
.navBarLinks .linkItem{
  text-decoration: none;
  margin-left: 10px;
  /* color: rgba(0, 0, 0, 0.637); */
  color: #5488bf;
  font-weight: 400 !important;
  padding: 5px 10px;
  border-radius: 2px;
  transition: 0.2s ease;
  cursor: pointer;
}
.navBarLinks .linkItem:hover {
  background-color: #ebf8ff;
  transition: 0.2s ease;
}
#brandText {
  font-size: 1.1rem;
}

/* ----------Responsiveness--------- */

@media (max-width: 1203px) {
  .navBarContainer {
    max-width: 950px;
  }
}
@media (max-width: 1050px) {
  .navBarContainer {
    max-width: 900px;
  }
}
@media (max-width: 928px) {
  .navBarContainer {
    max-width: 800px;
  }
}
@media (max-width: 824px) {
  .navBarContainer {
    max-width: 700px;
  }
}
@media (max-width: 730px) {
  .navBarContainer {
    max-width: 600px;
  }
}
@media (max-width: 632px) {
  .navBarContainer {
    max-width: 100% !important;
    padding: 4px 20px !important;
    /* margin: 0px 5px !important; */
  }
  .line {
    width: 22px;
    height: 3px;
    background: #3182ce;
    margin: 4px;
  }
  .navBarLinks .linkItem:hover {
  background-color: unset;
}
  .hamBurger {
    border: 1px solid #3182ce;
    padding: 3px;
    border-radius: 5px;
    cursor: pointer;
    z-index: 15;
    /* background-color: rgb(255, 255, 255); */
  }
  .linkItem {
    color: white !important;
    font-size: 23px !important;
    margin: 10px 10px !important;
  }
  .navBarLinks {
    padding: 100px 0px;
    /* display: none !important; */
    clip-path: circle(100px at 150% -20%);
    -webkit-clip-path: circle(100px at 90% -20%);
    transition: clip-path 0.5s ease-in;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh !important;
    flex-direction: column !important;
    width: 100%;
    display: flex;
    /* background-color: rgba(255, 255, 255, 0.8); */
    /* background: #12c2e954; 
    background: -webkit-linear-gradient(
      to left,
      #f64f5a6e,
      #c471ed59,
      #12c2e970
    ); 
    background: linear-gradient(
      to left,
      #f64f5a4b,
      #c471ed62,
      #12c2e970
    ); */

    /* background: #4e54c867;
    background: -webkit-linear-gradient(
      to right,
      #8f94fb6e,
      #4e54c875
    ); 
    background: linear-gradient(
      to right,
      #8f94fbb7,
      #4e54c8a8
    );  */
    background-color: #3182ce8f;
    backdrop-filter: blur(5px);
  }
  .navBarLinks.open {
    clip-path: circle(800px at 90% -20%);
    -webkit-clip-path: circle(800px at 90% -20%);
    transition: clip-path 0.5s ease-out;
  }
  .active {
    color: white !important;
    font-size: 23px !important;
    margin: 20px 0px;
  }
}
@media (max-width: 532px) {
  .navBarContainer {
    max-width: 400px;
  }
  .navBarLinks {
    /* display: none; */
    color: white !important;
  }
  .navBarMain {
    padding: 0;
    margin: 0;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    left: 0;
  }
  .navBarContainer {
    /* background-color: rgb(255,255,255,0.8); */

    backdrop-filter: blur(3px);
    /* margin: 0px 5px; */
    padding: 15px 20px;
    /* border-radius: 50px; */
    /* width: 85vw; */
    /* background: #12c2e93b; 
    background: -webkit-linear-gradient(
      to left,
      #f64f5a3b,
      #c471ed34,
      #12c2e93f
    ); 
    background: linear-gradient(
      to left,
      #f64f5a41,
      #c471ed4b,
      #12c2e950
    );  */

    /* background: #4e54c85d; 
    background: -webkit-linear-gradient(
      to right,
      #8f94fb5d,
      #4e54c82d
    ); 
    background: linear-gradient(
      to right,
      #8f94fb52,
      #4e54c854
    );  */
  }
}
@media (max-width: 460px) {
  .navBarContainer {
    max-width: 100%;
  }
}
