.footerMain {
  display: flex;
  justify-content: center;
  font-size: 1.1rem;
  margin: 100px 0px 20px;
}
.footerContainer {
  width: 100%;
  max-width: 1100px;
}
.footerHeading {
  font-size: 2rem;
  font-weight: 800;
}
.footerDesc {
  padding: 10px 0px 80px;
}

.footerSeparator {
  background-color: rgb(212, 212, 212);
  outline: none;
  border: none;
  width: 100%;
  padding: 0;
  height: 1px;
  margin-bottom: 30px;
}
.footerCopyRight {
  margin-bottom: 50px;
  font-size: 1rem;
}
.footerContainer span {
  background: #d9003a;
  background: -webkit-linear-gradient(to right, #d9003a 50%, #e3d61e 100%);
  background: -moz-linear-gradient(to right, #d9003a 50%, #e3d61e 100%);
  background: linear-gradient(to right, #d9003a 50%, #e3d61e 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

/* ----------Responsiveness----------- */
@media (max-width: 1203px) {
  .footerContainer {
    width: 100%;
    max-width: 950px;
  }
}
@media (max-width: 1050px) {
   .footerContainer {
    width: 100%;
    max-width: 1000px;
  }
}
@media (max-width: 1020px) {
   .footerContainer {
    width: 100%;
    max-width: 900px;
  }
}
@media (max-width: 928px) {
   .footerContainer {
    width: 100%;
    max-width: 800px;
  }
}
@media (max-width: 824px) {
   .footerContainer {
    width: 100%;
    max-width: 700px;
  }
}
@media (max-width: 726px) {
   .footerContainer {
    width: 100%;
    max-width: 650px;
  }
}
@media (max-width: 674px) {
   .footerContainer {
    width: 100%;
    max-width: 600px;
  }
}
@media (max-width: 618px) {
   .footerContainer {
    width: 100%;
    max-width: 500px;
  }
}

@media (max-width: 532px) {
   .footerContainer {
    width: 100%;
    max-width: 400px;
  }
}
@media (max-width: 420px) {
   .footerContainer {
    width: 100%;
    max-width: 330px;
  }
}