@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
body {
  margin: 0;
  font-family: "Karla", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  background-color: #fcfdff !important;
  /* background-color: #f5f6ff !important; */
  /* background-color: #1A202C;
  color: #A0AEC0; */
  overflow-x: hidden !important;
}
html {
  padding: 0;
  margin: 0;
}

code {
  font-family: "Karla", sans-serif;
}

.modal {
  position: fixed;
  z-index: 1000 !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* overflow: auto; */
  background-color: rgba(0, 0, 0, 0.4);
  /* transition: opacity 500ms;
  visibility: hidden;
  opacity: 0; */
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
}
/* .modal:target {
  visibility: visible;
  opacity: 1;
} */

.modalContainer {
  /* padding: 50px 0px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;


  position: "absolute";
  top: "50%";
  left: "50%";
  transform: "translate(-50%, -50%)";
  width: 400;
  background-color: white;
  border: "2px solid #000";
  box-shadow: 24;
  padding: 4;
  z-index: 50000; */
}
.modalContainer .projectHeaderText {
  font-size: 2rem !important;
  font-weight: 800;
  color: #3a22cc;
  margin-bottom: 10px;
}
.modalContainer .statusCode {
  padding: 10px 0px !important;
  background-color: #ffedd5;
}
.modalContainer .colorChange {
  background-color: #dd6b20;
  color: white;
  padding: 2px 5px;
  border-radius: 5px;
}
.modalContainer .projectDescText {
  font-size: 1.1rem !important;
  font-weight: 500;
  padding: 10px 50px;
  margin-bottom: 10px;
}
.modalContent {
  font-family: "Karla", sans-serif !important;
  background-color: #fcfdff !important;
  width: 500px;
  min-height: 350px;
  border-radius: 10px;
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  /* align-items: center; */
}
.modalButtonList {
  min-width: 350px !important;
  display: flex;
  /* flex-direction: row; */
  justify-content: space-evenly;
  align-items: center;
}
.modalContent button {
  font-family: "Karla", sans-serif !important;
  width: 180px;
  height: 50px;
  background-color: #968ed3;
  color: white;
  font-size: 1.1rem;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s ease;
  align-items: center !important;
  justify-content: center !important;
  display: flex !important;
}
.modalContent button:hover {
  background-color: #4d3faa;
  transform: scale(1.05);
  transition: 0.3s ease;
}
.modalContent button img {
  color: white !important;
}

@media (max-width: 525px) {
  .modalContent {
    width: 450px;
  }
}
@media (max-width: 465px) {
  .modalContent {
    width: 400px;
  }
  .modalContainer .projectDescText {
    font-size: 1.1rem !important;
    font-weight: 500;
    padding: 10px;
    margin-bottom: 10px;
  }
}
@media (max-width: 415px) {
  .modalContent {
    width: auto;
    margin: 0px 10px;
  }
  .modalContent button {
    width: 150px;
  }
}
