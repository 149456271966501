.skillCardMain {
  display: flex;
  flex-direction: column;
  /* width: 350px; */
  height: min-content;
  align-items: center;
  justify-content: center;
  /* background-color: white; */
  margin: 10px 0px;
  border-radius: 10px;
  z-index: 15;
  position: relative;
}
.skillImage {
  width: 100px;
  height: 100px;
  font-size: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  -webkit-clip-path: polygon(
    50% 0%,
    95% 25%,
    95% 75%,
    50% 100%,
    5% 75%,
    5% 25%
  );
  clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
}
.skillDescBox {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 350px;
  border-radius: 10px;
  padding: 30px 0px;
  margin-top: -30px;
  /* box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075),
    0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075),
    0 8px 8px hsl(0deg 0% 0% / 0.075), 0 16px 16px hsl(0deg 0% 0% / 0.075); */

  /* box-shadow: 0.8px 1.1px 2.2px rgba(0, 0, 0, 0.02),
    2px 2.7px 5.3px rgba(0, 0, 0, 0.028), 3.8px 5px 10px rgba(0, 0, 0, 0.035),
    6.7px 8.9px 17.9px rgba(0, 0, 0, 0.042),
    12.5px 16.7px 33.4px rgba(0, 0, 0, 0.05), 30px 40px 80px rgba(0, 0, 0, 0.07); */

    /* box-shadow:
  0px 1.2px 2.2px rgba(0, 0, 0, 0.043),
  0px 2.9px 5.3px rgba(0, 0, 0, 0.06),
  0px 5.4px 10px rgba(0, 0, 0, 0.075),
  0px 9.6px 17.9px rgba(0, 0, 0, 0.09),
  0px 18px 33.4px rgba(0, 0, 0, 0.11),
  0px 43px 80px rgba(0, 0, 0, 0.14)
; */
box-shadow:
  0px 1.2px 2.2px rgba(0, 0, 0, 0.015),
  0px 2.9px 5.3px rgba(0, 0, 0, 0.022),
  0px 5.4px 10px rgba(0, 0, 0, 0.027),
  0px 9.6px 17.9px rgba(0, 0, 0, 0.032),
  0px 18px 33.4px rgba(0, 0, 0, 0.039),
  0px 43px 80px rgba(0, 0, 0, 0.05)
;


}
.skillCardTitle {
  text-align: center;
  font-size: 1.4rem;
  font-weight: 600;
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.skillCardDesc {
  text-align: center;
  font-size: 1.1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
}

@media (max-width: 928px) {
  .skillDescBox {
    max-width: 350px !important;
  }
}
@media (max-width: 824px) {
  .skillDescBox {
    max-width: 300px !important;
  }
}
@media (max-width: 674px) {
  .skillDescBox {
    max-width: 280px !important;
  }
}
@media (max-width: 618px) {
  .skillDescBox {
    max-width: 450px !important;
  }
}
@media (max-width: 532px) {
  .skillDescBox {
    max-width: 400px !important;
  }
}
