.projectsMain {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 40px 0px;
  position: relative;
  /* background-color: rgba(0, 0, 255, 0.027); */
  background-color: #f5f6ff;
  background-blend-mode: lighten;
  z-index: 5;
  margin-bottom: -800px;
}
.subtleBackProject {
  position: absolute;
  font-weight: 600;
  font-size: 15rem;
  /* color: rgba(0, 0, 255, 0.089); */
  color: #d2d3f0;
  z-index: 0;
  top: -60px;
  z-index: -1;
}
.projectsContainer {
  max-width: 1100px;
  width: 100%;
}
.projectsHeading {
  font-size: 2.1rem;
  font-weight: 800;
}
.projectsSubHeading {
  font-size: 1.2rem;
  padding: 100px 0px 10px;
  max-width: 900px;
}

.projectsList {
  padding-top: 80px ;
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-between;
  align-items: center;
}
/* .projectsList::after {
  content: "";
  flex: 350px !important;
} */

/* ---------Responsiveness--------- */
@media (max-width: 1203px) {
  .projectsContainer {
    max-width: 950px;
    width: 100%;
  }
}
@media (max-width: 1050px) {
  .projectsContainer {
    max-width: 1000px;
    width: 100%;
  }
}
@media (max-width: 1020px) {
  .projectsMain {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* margin: 100px 0px; */
  }
  .projectsContainer {
    max-width: 900px;
    width: 100%;
  }
  .projectsHeading {
    font-size: 2.1rem;
    font-weight: 800;
  }
  .projectsSubHeading {
    font-size: 1.1rem;
    padding: 10px 0px;
  }

  .projectsList {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-items: center;
  }
}
@media (max-width: 999px) {
  .subtleBackProject {
    font-size: 10rem;
  }
}
@media (max-width: 928px) {
  .projectsMain {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* margin: 100px 0px; */
    margin-bottom: -900px;
  }
  .projectsContainer {
    max-width: 800px;
    width: 100%;
  }
  .projectsHeading {
    font-size: 2.1rem;
    font-weight: 800;
  }
  .projectsSubHeading {
    font-size: 1.1rem;
    padding: 10px 0px;
  }

  .projectsList {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 824px) {
  .projectsMain {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* margin: 100px 0px; */
  }
  .projectsContainer {
    max-width: 700px;
    width: 100%;
  }
  .projectsHeading {
    font-size: 2.1rem;
    font-weight: 800;
  }
  .projectsSubHeading {
    font-size: 1.1rem;
    padding: 10px 0px;
  }

  .projectsList {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 726px) {
  .projectsMain {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* margin: 100px 0px; */
  }
  .projectsContainer {
    max-width: 650px;
    width: 100%;
  }
  .projectsHeading {
    font-size: 2.1rem;
    font-weight: 800;
  }
  .projectsSubHeading {
    font-size: 1.1rem;
    padding: 10px 0px;
  }

  .projectsList {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-items: center;
  }
}
@media (max-width: 674px) {
  .projectsMain {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* margin: 100px 0px; */
  }
  .projectsContainer {
    max-width: 600px;
    width: 100%;
  }
  .projectsHeading {
    font-size: 2.1rem;
    font-weight: 800;
  }
  .projectsSubHeading {
    font-size: 1.1rem;
    padding: 10px 0px;
  }

  .projectsList {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-items: center;
  }
}
@media (max-width: 630px) {
  .subtleBackProject {
    font-size: 8rem;
  }
}
@media (max-width: 618px) {
  .projectsMain {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* margin: 100px 0px; */
    margin-bottom: -1600px;
  }
  .projectsContainer {
    max-width: 550px;
    width: 100%;
  }
  .projectsHeading {
    font-size: 2.1rem;
    font-weight: 800;
  }
  .projectsSubHeading {
    font-size: 1.1rem;
    padding: 10px 0px;
  }

  .projectsList {
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 532px) {
  .projectsMain {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* margin: 100px 0px; */
  }
  .projectsContainer {
    max-width: 400px;
    width: 100%;
  }
  .projectsHeading {
    font-size: 2.1rem;
    font-weight: 800;
  }
  .projectsSubHeading {
    font-size: 1.1rem;
    padding: 10px 0px;
  }

  .projectsList {
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
    align-items: center;
  }

  .subtleBackProject {
    font-size: 6rem;
    top: -40px;
  }
}
@media (max-width: 420px) {
  .projectsMain {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* margin: 100px 0px; */
  }
  .projectsContainer {
    max-width: 380px;
    width: 100%;
  }
  .projectsHeading {
    font-size: 2.1rem;
    font-weight: 800;
  }
  .projectsSubHeading {
    font-size: 1.1rem;
    padding: 10px 0px;
  }

  .projectsList {
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 398px) {
  .projectsMain {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* margin: 100px 0px; */
    margin-bottom: -1650px;
  }
  .projectsContainer {
    max-width: 360px;
    width: 100%;
  }
  .projectsHeading {
    font-size: 2.1rem;
    font-weight: 800;
    padding-left: 10px;
  }
  .projectsSubHeading {
    font-size: 1.1rem;
    padding: 10px 0px 10px 10px;
  }

  .projectsList {
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
    align-items: center;
  }
}
