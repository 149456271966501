/* ---------------ProjectCard----------------- */
.ProjectCardEntire {
  height: 505px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
}

.projectsCardMain {
  margin: 30px 0px;
  overflow: hidden;
  width: 350px;
  height: 250px;
  border-radius: 10px;
  /* box-shadow: 5.4px 10.9px 10.9px hsl(0deg 0% 0% / 0.33); */
  box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075),
    0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075),
    0 8px 8px hsl(0deg 0% 0% / 0.075), 0 16px 16px hsl(0deg 0% 0% / 0.075);
  transition: all 0.3s ease;
}
.projectsCardMain:hover {
  transform: scale(1.05);
  transition: all 0.3s ease;
  box-shadow: 0.8px 1.1px 2.2px rgba(0, 0, 0, 0.02),
    2px 2.7px 5.3px rgba(0, 0, 0, 0.028), 3.8px 5px 10px rgba(0, 0, 0, 0.035),
    6.7px 8.9px 17.9px rgba(0, 0, 0, 0.042),
    12.5px 16.7px 33.4px rgba(0, 0, 0, 0.05), 30px 40px 80px rgba(0, 0, 0, 0.07);
  cursor: pointer;
}
.projectTagList {
  display: flex;
  font-size: 1rem !important;
  flex-wrap: wrap;
  margin-top: 5px;
}
.projectTag {
  margin: 2px 5px;
  background-color: #351bcac2;
  color: white;
  padding: 5px;
  border-radius: 5px;
}
.externalLinks {
  display: none;
}
.statusDiv {
  display: none;
}
.projectsCardMain .upperSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(238, 242, 243);
}
.quickActions {
  padding: 10px 0px 10px 10px;
  display: flex;
}
.circle {
  /* background-color: rgb(204, 209, 213); */
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin: 0px 2px;
}
.c1 {
  background-color: #f75d59;
}
.c2 {
  background-color: #f7b742;
}
.c3 {
  background-color: #00c44c;
}
.searchBar {
  width: 240px;
  height: 20px;
  background-color: white;
  border-radius: 5px;
  font-size: 0.7rem !important;
  display: flex;
  align-items: center;
  padding-left: 5px;
  overflow: hidden;
  white-space: nowrap;
}
.arrowHead {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px 10px 0px;
}
.projectMainContent {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}
.projectMainContent img {
  width: 350px ;
  height: 215px;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
}
.textContent {
  height: 215px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.textContent h2 {
  font-weight: 900 !important;
  font-size: 2.09rem !important;
  background: linear-gradient(
    to right,
    rgb(42, 189, 133) 10%,
    rgb(44, 108, 238) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}
.cardDetails {
  width: 350px;
}
.projectTitle {
  font-size: 1.7rem !important;
  font-weight: 700 !important;
}
.projectDesc {
  margin-top: 10px;
  font-size: 1.1rem;
}

/* ------------Responsiveness----------- */
@media (max-width: 1203px) {
  .projectsCardMain {
    margin: 30px 0px;
    width: 300px;
    height: 200px;
  }
  .textContent {
    height: 215px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .searchBar {
    width: 210px;
    height: 20px;
  }
  .cardDetails {
    width: 320px;
  }
  .ProjectCardEntire {
    height: 480px;
  }
}
@media (max-width: 1020px) {
  .projectsCardMain {
    margin: 20px 0px;
    width: 400px !important;
    height: 250px !important;
  }
  .textContent {
    height: 215px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cardDetails {
    width: 400px;
  }
  .ProjectCardEntire {
    height: 500px;
  }
}

@media (max-width: 928px) {
  .projectsCardMain {
    margin: 20px 0px;
    width: 350px !important;
    height: 250px !important;
  }
  .textContent {
    height: 215px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cardDetails {
    width: 350px;
  }
  .ProjectCardEntire {
    height: 510px;
  }
}
@media (max-width: 824px) {
  .projectsCardMain {
    margin: 20px 0px;
    width: 300px !important;
    height: 250px !important;
  }
  .textContent {
    height: 215px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cardDetails {
    width: 320px;
  }
  .ProjectCardEntire {
    height: 510px;
  }
}
@media (max-width: 726px) {
  .projectsCardMain {
    margin: 20px 0px;
    width: 310px !important;
    height: 230px !important;
  }
  .textContent {
    height: 215px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cardDetails {
    width: 310px;
  }
  .ProjectCardEntire {
    height: 500px;
  }
}
@media (max-width: 674px) {
  .projectsCardMain {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
    width: 280px !important;
    height: 220px !important;
    background: #7f7fd5;
    background: -webkit-linear-gradient(to right, #91eae4, #86a8e7, #7f7fd5);
    background: linear-gradient(to right, #91eae4, #86a8e7, #7f7fd5);
  }
  .projectCardContainer {
    width: 300px !important;
    height: 200px !important;
    overflow: hidden;
    border-radius: 10px;
  }
  .textContent {
    height: 215px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cardDetails {
    width: 280px;
  }
  .ProjectCardEntire {
    height: 510px;
  }
}
@media (max-width: 618px) {
  .projectsCardMain {
    margin: 20px 0px;
    width: 500px !important;
    height: 420px !important;
  }
  .textContent {
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cardDetails {
    width: 500px;
  }
  .ProjectCardEntire {
    height: 450px;
  }
  .projectMainContent img {
    width: 100%;
    height: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
  }
}
@media (max-width: 532px) {
  .projectsCardMain {
    margin: 20px 0px;
    width: 400px !important;
    height: 280px !important;
  }
  .textContent {
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cardDetails {
    width: 400px;
  }
  .ProjectCardEntire {
    height: 420px;
  }
  .projectMainConten img {
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
  }
}
@media (max-width: 420px) {
  .projectsCardMain {
    margin: 20px 0px;
    width: 350px !important;
    height: 280px !important;
  }
  .textContent {
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cardDetails {
    width: 350px;
  }
  .ProjectCardEntire {
    height: 420px;
  }
  .projectMainContent img {
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
  }
}
@media (max-width: 398px) {
  .projectsCardMain {
    margin: 20px 0px;
    width: 340px !important;
    height: 230px !important;
  }
  .projectCardContainer {
    width: 300px !important;
    height: 200px !important;
    overflow: hidden;
    border-radius: 10px;
  }
  .textContent {
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cardDetails {
    width: 320px;
  }
  .ProjectCardEntire {
    height: min-content;
  }
  .projectMainContent img {
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
  }
  .searchBar {
    width: 200px;
    height: 20px;
    font-size: 0.6rem !important;
  }
}
