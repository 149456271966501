.skillMain {
  display: flex;
  justify-content: center;
  /* padding: 140px 0px 100px; */
  /* background-color: rgba(0, 128, 128, 0.212); */
  background-color: #c7e2e4;
  padding: 30px 0px;
  position: relative;
  z-index: 15 !important;
  margin-bottom: -100px;
}
.SubtleBack {
  font-size: 15rem;
  position: absolute;
  /* color: rgba(0, 128, 128, 0.212); */
  color: #8fbbbd;
  z-index: -1;
  top: -50px;
  font-weight: 600;
}
.skillContainer {
  max-width: 1100px;
  width: 100%;
}
.skillHeading {
  font-size: 2.1rem;
  font-weight: 800;
}
.skillDesc {
  font-size: 1.2rem;
  padding: 10px 0px;
  max-width: 900px;
}
.skillList {
  padding: 50px 0px;
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-between;
}

@media (max-width: 1203px) {
  .skillContainer {
    max-width: 950px;
  }
}
@media (max-width: 1050px) {
  .skillContainer {
    max-width: 1000px;
  }
}
@media (max-width: 1020px) {
  .skillContainer {
    max-width: 900px;
  }
}
@media (max-width: 928px) {
  .skillMain{
    margin-bottom: -150px;
  }
  .skillContainer {
    max-width: 800px;
  }
  .skillList {
    grid-template-columns: auto auto;
  }
}
@media (max-width: 824px) {
  .skillContainer {
    max-width: 700px;
  }
  .skillMain{
    margin-bottom: -200px;
  }
}
@media (max-width: 726px) {
  .skillContainer {
    max-width: 650px;
  }
}
@media (max-width: 674px) {
  .skillContainer {
    max-width: 600px;
  }
  .SubtleBack {
    font-size: 10rem;
  }
}
@media (max-width: 618px) {
   .skillMain{
    margin-bottom: -450px !important;
  }
  .skillContainer {
    max-width: 550px;
  }
  .skillList {
    grid-template-columns: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .skillMain{
    margin-bottom: -400px;
  }
}
@media (max-width: 532px) {
  .skillMain {
    /* margin: 10px 0px; */
  }
  .skillContainer {
    max-width: 400px;
  }
  .skillList {
    grid-template-columns: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
  }
  .SubtleBack {
    font-size: 6rem;
  }
}
@media (max-width: 420px) {
  .skillContainer {
    max-width: 380px;
  }
  .skillList {
    grid-template-columns: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 398px) {
  .skillContainer {
    max-width: 360px;
  }
  .skillList {
    grid-template-columns: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .skillMain{
    margin-bottom: -525px !important;
  }
}
